import React, { useState, useEffect, useMemo } from 'react';
import HeadingTitle from '../layout/HeadingTitle';
import Lineups from '../formation/Lineups';
import Loader from '../../shared/Loader';
import InjuredSuspendedContent from './InjuredSuspendedContent';
const MatchFormationTab = ({
  matchDetails,
  matchFormation,
  matchEvents,
  injuredSuspendedGuest,
  injuredSuspendedHome,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [homeFormation, setHomeFormation] = useState('');
  const [guestFormation, setGuestFormation] = useState('');
  const [homeOfficial, setHomeOfficial] = useState('');
  const [guestOfficial, setGuestOfficial] = useState('');
  useEffect(() => {
    if (!matchFormation) return;

    const homeData = extractFormation(matchFormation.homeTeamLineup || []);
    const guestData = extractFormation(matchFormation.guestTeamLineup || []);

    setHomeFormation(homeData.formation);
    setHomeOfficial(homeData.official);
    setGuestFormation(guestData.formation);
    setGuestOfficial(guestData.official);
    setIsLoading(false);
  }, [matchFormation]);
  const extractFormation = (teamLineup) => {
    for (let i = teamLineup.length - 1; i >= 0; i--) {
      if (teamLineup[i]?.formationUsed !== '') {
        return {
          formation: teamLineup[i].formationUsed,
          official: teamLineup[i].teamOfficial,
        };
      }
    }
    return { formation: '', official: '' };
  };

  const extractUniquePlayers = (players, isStarting, prioritizeLast = true) => {
    const uniquePlayersMap = new Map();
    const positionMap = new Map();

    // Reverse the players array to process the most recent data first if prioritizeLast is true
    const playersToProcess =
      isStarting && prioritizeLast ? [...players].reverse() : players;

    playersToProcess.forEach((player) => {
      const isValidPlayer =
        player?.isStarting === isStarting &&
        (isStarting
          ? player.formationPosition !== null
          : player.formationPosition === null);

      if (isValidPlayer) {
        const existingPlayerForPosition = positionMap.get(
          player.formationPosition
        );

        // Ensure no duplicate playerIds are added
        if (!uniquePlayersMap.has(player.playerId)) {
          // If no player exists for the position, or if we prioritize the last player, add/replace the player
          if (!existingPlayerForPosition) {
            uniquePlayersMap.set(player.playerId, player);
            if (player.formationPosition !== null) {
              positionMap.set(player.formationPosition, player);
            }
          }
        }
      }
    });
    if (isStarting && positionMap.size < 11) {
      const filledPositions = new Set(positionMap.keys());
      const allPositions = new Set([...Array(11).keys()].map((i) => i + 1)); // Assuming positions are from 1 to 11
      const missingPositions = [...allPositions].filter(
        (pos) => !filledPositions.has(pos)
      );

      missingPositions.forEach((missingPosition) => {
        const playerToFill = players.find(
          (p) =>
            p.formationPosition === missingPosition &&
            !positionMap.has(p.playerId)
        );
        if (playerToFill) {
          positionMap.set(missingPosition, playerToFill);
        }
      });
    }
    // Convert the map back to an array
    return isStarting
      ? Array.from(positionMap.values()) // Return players in formation order if isStarting
      : Array.from(uniquePlayersMap.values()); // Return the unique list of players otherwise
  };

  const homePlayers = useMemo(
    () => extractUniquePlayers(matchFormation?.homeTeam || [], true),
    [matchFormation]
  );
  const guestPlayers = useMemo(
    () => extractUniquePlayers(matchFormation?.guestTeam || [], true),
    [matchFormation]
  );
  const homeSubs = useMemo(
    () => extractUniquePlayers(matchFormation?.homeTeam || [], false),
    [matchFormation]
  );

  const homeSubsSet = useMemo(
    () => new Set(homeSubs.map((player) => player.playerId)),
    [homeSubs]
  );
  // Filter guest substitutes to exclude those who are also in home substitutes
  const guestSubs = useMemo(
    () =>
      extractUniquePlayers(matchFormation?.guestTeam || [], false).filter(
        (player) => !homeSubsSet.has(player.playerId)
      ),
    [matchFormation, homeSubsSet]
  );

  function formatAndSortPlayers(players, formation) {
    const formationMap = {
      442: [3, 6, 5, 2, 11, 8, 4, 7, 9, 10],
      41212: [3, 6, 5, 2, 4, 11, 7, 8, 9, 10],
      433: [3, 6, 5, 2, 8, 4, 7, 11, 9, 10],
      451: [3, 6, 5, 2, 11, 8, 10, 4, 7, 9],
      4411: [3, 6, 5, 2, 11, 8, 4, 7, 10, 9],
      4141: [3, 6, 5, 2, 4, 11, 10, 8, 7, 9],
      4231: [3, 6, 5, 2, 4, 8, 11, 10, 7, 9],
      4321: [3, 6, 5, 2, 7, 4, 8, 11, 10, 9],
      532: [3, 4, 5, 6, 2, 11, 8, 7, 9, 10],
      541: [3, 4, 5, 6, 2, 11, 10, 8, 7, 9],
      352: [4, 5, 6, 3, 8, 11, 7, 2, 9, 10],
      343: [4, 5, 6, 3, 8, 7, 2, 11, 9, 10],
      4222: [3, 6, 5, 2, 8, 4, 11, 7, 9, 10],
      3511: [4, 5, 6, 3, 8, 11, 7, 2, 10, 9],
      3421: [4, 5, 6, 3, 8, 7, 2, 11, 10, 9],
      3412: [4, 5, 6, 3, 8, 7, 2, 9, 11, 10],
      3142: [6, 5, 4, 8, 3, 11, 7, 2, 10, 9],
      '343d': [4, 5, 6, 8, 3, 2, 7, 11, 9, 10],
      4132: [3, 6, 5, 2, 4, 11, 8, 7, 10, 9],
      4240: [3, 6, 5, 2, 8, 4, 11, 10, 9, 7],
      4312: [3, 6, 5, 2, 11, 4, 7, 8, 10, 9],
      3241: [4, 5, 6, 3, 2, 11, 8, 7, 10, 9],
      3331: [4, 5, 6, 3, 8, 2, 11, 7, 10, 9],
      // Add more formations as needed
    };

    const formationIndices = formationMap[formation];
    if (formationIndices) {
      return players.sort(
        (a, b) =>
          formationIndices.indexOf(a.formationPosition) -
          formationIndices.indexOf(b.formationPosition)
      );
    }
    return players;
  }

  const sortedHomePlayers = useMemo(
    () => formatAndSortPlayers(homePlayers, homeFormation),
    [homePlayers, homeFormation]
  );
  const sortedGuestPlayers = useMemo(
    () => formatAndSortPlayers(guestPlayers, guestFormation),
    [guestPlayers, guestFormation]
  );

  const countPlayerEvents = (events, playerId) => {
    return events.reduce((counts, event) => {
      if (event.playerMainSourceId === playerId) {
        counts[event.type] = (counts[event.type] || 0) + 1;
      }
      return counts;
    }, {});
  };

  const EventIndicatorSubs = ({ player, events }) => {
    const eventCounts = useMemo(
      () => countPlayerEvents(events, player?.playerId),
      [events, player?.playerId]
    );
    const eventIcons = {
      1: '/img/svg/goal.svg',
      2: '/img/svg/yellowCard.svg',
      3: '/img/svg/ownGoal.svg',
      4: '/img/svg/redCard.svg',
      8: '/img/svg/secondYellow.svg',
    };

    return (
      <div>
        {Object.entries(eventCounts).map(([eventType, count]) => (
          <React.Fragment key={eventType}>
            {Array.from({ length: count }).map((_, index) => (
              <img
                key={index}
                src={eventIcons[eventType]}
                width={12}
                height={14}
                alt={eventType}
                className="inline mb-1"
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const filteredEvents = useMemo(() => {
    const uniquePlayers = new Set();
    if (!matchEvents || !matchEvents.events) return [];
    return matchEvents.events.filter((event) => {
      if (event.type === 7 && !uniquePlayers.has(event.playerMain)) {
        uniquePlayers.add(event.playerMain);
        return true;
      }
      return false;
    });
  }, [matchEvents]);
  if (isLoading) {
    return <Loader />;
  }

  const PlayerList = ({ players, events, isHome }) => (
    <table className="w-full border border-bg-divider">
      <tbody>
        {players &&
          players.map((player, index) => (
            <tr key={index} className="h-12">
              <td className="border order-bg-divider relative">
                <div className="flex items-center pl-5 relative">
                  <div className="relative">
                    <img
                      src={
                        isHome ? '/img/svg/kitHome.svg' : '/img/svg/kitAway.svg'
                      }
                      alt="Kit"
                      className="object-cover h-[22px] w-[24px] z-10"
                    />
                    <div className="absolute top-3 left-3 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center font-light text-white text-lineupsText">
                      {player?.shirtNumber}
                    </div>
                  </div>
                  <div className="ml-2 items-center">
                    <div className="flex text-lineupsText pl-1">
                      {player?.playerName}

                      <div className="flex ml-1">
                        {events &&
                          events.some(
                            (event) =>
                              event?.playerMainSourceId === player?.playerId
                          ) && (
                            <EventIndicatorSubs
                              player={player}
                              events={events}
                            />
                          )}
                      </div>
                    </div>
                    {events &&
                      filteredEvents
                        .filter(
                          (event) =>
                            event?.playerOtherSourceId === player?.playerId
                        )
                        .map((event, index) => (
                          <div key={index} className="flex items-center ml-1">
                            <img
                              src="/img/svg/subs.svg"
                              alt="Substitution"
                              className="object-cover rounded-full h-4 w-4 mr-1"
                            />
                            <p className="text-xs">
                              <span className="text-green-600 mr-1">
                                {event?.minute}'
                              </span>
                              <span className="text-textSecondary">
                                {event?.playerMain}
                              </span>
                            </p>
                          </div>
                        ))}
                  </div>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <Lineups
        homeTeamPlayers={sortedHomePlayers}
        guestTeamPlayers={sortedGuestPlayers}
        homeFormation={homeFormation}
        guestFormation={guestFormation}
        isLoading={isLoading}
        matchEvents={matchEvents}
      />
      <div className="flex">
        <div className="flex-1">
          <table className="w-full border border-bg-divider">
            <tbody>
              <tr>
                <td className="border border-bg-divider p-2">
                  <div className="flex p-2">
                    <img
                      src={matchDetails?.matches[0].homeTeam.logoUrl}
                      alt={matchDetails?.matches[0].homeTeam.name}
                      className="object-cover rounded-full h-[24px] w-[24px]"
                    />
                    <h2 className="uppercase pl-2 text-textSecondary text-scoreText pt-0.5">
                      {matchDetails?.matches[0].homeTeam.name}
                    </h2>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-gray-100">
                  <div className="flex items-center pl-5 p-1">
                    <img
                      src="/img/svg/kitHome.svg"
                      alt="Home kit"
                      className="object-cover h-[22px] w-[24px] z-10"
                    />
                    <div className="ml-2">
                      <p className="text-lineupsText ">{homeOfficial}</p>
                      <p className="text-xs text-textSecondary">Trener</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex-1">
          <table className="w-full border border-bg-divider">
            <tbody>
              <tr>
                <td className="border order-bg-divider p-2">
                  <div className="flex p-2">
                    <img
                      src={matchDetails?.matches[0].guestTeam.logoUrl}
                      alt={matchDetails?.matches[0].guestTeam.name}
                      className="object-cover rounded-full h-[24px] w-[24px]"
                    />
                    <h2 className="uppercase pl-2 text-textSecondary text-scoreText pt-0.5">
                      {matchDetails?.matches[0].guestTeam.name}
                    </h2>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-gray-100">
                  <div className="flex items-center pl-5 p-1">
                    <img
                      src="/img/svg/kitAway.svg"
                      alt="Away kit"
                      className="oobject-cover  h-[22px] w-[24px] z-10"
                    />
                    <div className="ml-2">
                      <p className="text-lineupsText">{guestOfficial}</p>
                      <p className="text-xs text-textSecondary">Trener</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <HeadingTitle title={'Rezervni igrači'} />
      <div className="flex">
        <div className="flex-1">
          <PlayerList
            players={homeSubs}
            events={matchEvents?.events}
            isHome={1}
          />
        </div>
        <div className="flex-1">
          <PlayerList
            players={guestSubs}
            events={matchEvents?.events}
            isHome={0}
          />
        </div>
      </div>
      <InjuredSuspendedContent
        injuredSuspendedGuest={injuredSuspendedGuest}
        injuredSuspendedHome={injuredSuspendedHome}
      />
    </div>
  );
};

export default MatchFormationTab;
