import React, { useRef, useState, useEffect } from "react";

const HeatMap = ({ data }) => {
  const containerRef = useRef(null);
  const [svgDimensions, setSvgDimensions] = useState({ width: 500, height: 300 }); // Initial default dimensions

  useEffect(() => {
    // Update the dimensions based on the parent container
    const updateDimensions = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setSvgDimensions({ width: clientWidth, height: clientHeight });
      }
    };

    updateDimensions(); // Initial update
    window.addEventListener("resize", updateDimensions); // Update on resize

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  if (!data || !data.shots || !data.passes || !data.tackles || !data.touches) {
    return <div className="p-1">Trenutno nema dostupnih podataka</div>; // Handle missing data
  }

  const gridSize = 10;

  const createHeatmapData = (events, gridSize, dimensions) => {
    const heatmap = Array.from({ length: gridSize }, () => Array(gridSize).fill(0));

    events.forEach((event) => {
      const { x, y } = event;
      const cellX = Math.floor((x / dimensions.width) * gridSize);
      const cellY = Math.floor((y / dimensions.height) * gridSize);

      if (cellX >= 0 && cellX < gridSize && cellY >= 0 && cellY < gridSize) {
        heatmap[cellY][cellX] += 1;
      }
    });

    return heatmap;
  };

  const getHeatmapColorForCSS = (intensity) => {
    if (intensity < 0.33) {
      return `rgb(255, 255, 0)`; // Yellow
    } else if (intensity < 0.66) {
      return `rgb(255, 165, 0)`; // Orange
    } else {
      return `rgb(255, 0, 0)`; // Red
    }
  };

  const renderHeatmap = (heatmap, dimensions) => {
    const maxDensity = Math.max(...heatmap.flat());
    const cellSize = dimensions.width / gridSize;

    return heatmap.flatMap((row, rowIndex) =>
      row.map((cell, cellIndex) => {
        const intensity = cell / maxDensity;

        if (cell === 0) return null;

        const x = cellIndex * cellSize;
        const y = rowIndex * cellSize;

        return (
          <rect
            key={`${rowIndex}-${cellIndex}`}
            x={x}
            y={y}
            width={cellSize}
            height={cellSize}
            fill={getHeatmapColorForCSS(intensity)}
          />
        );
      })
    );
  };

  const allEvents = [
    ...data.shots,
    ...data.passes,
    ...data.tackles,
    ...data.touches,
  ];

  const heatmap = createHeatmapData(allEvents, gridSize, svgDimensions);

  return (
    <div className="relative w-full h-80" ref={containerRef}>
      <img
        src="/img/svg/heatfield.svg"
        alt="Pitch Background"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <svg
        className="absolute inset-0 w-full h-full"
        viewBox={`0 0 ${svgDimensions.width} ${svgDimensions.height}`}
        preserveAspectRatio="none"
      >
        <defs>
          <filter id="blur-effect" x="0" y="0" width="200%" height="200%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="20" />
          </filter>
        </defs>

        <g filter="url(#blur-effect)">
          {renderHeatmap(heatmap, svgDimensions)}
        </g>
      </svg>
    </div>
  );
};

export default HeatMap;
