import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

const ShotMap = ({ data }) => {
  const [selectedText, setSelectedText] = useState("Teren");
  const [selectedShot, setSelectedShot] = useState(null);
  const svgRef = useRef(null);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const [shotsReady, setShotsReady] = useState(false);

  // Function to update dimensions
  const updateDimensions = () => {
    if (svgRef.current) {
      const { width, height } = svgRef.current.getBoundingClientRect();
      setSvgDimensions({ width, height });
    }
    setIsMobile(window.innerWidth <= 768);
  };

  // Update dimensions and check for mobile on mount and resize
  useLayoutEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // Recalculate dimensions on selectedText change
  useEffect(() => {
    setShotsReady(false);
    const timer = setTimeout(() => {
      updateDimensions();
      setShotsReady(true);
    }, 100); // Small delay to ensure dimensions are updated
    return () => clearTimeout(timer);
  }, [selectedText]);

  // Filter shots based on the selected type (Teren or Gol)
  const filteredShots = data.filter((shot) =>
    selectedText === "Teren" ? shot.type === "Field" : shot.type === "Goal"
  );

  // Initialize the first shot as selected on mount
  useEffect(() => {
    if (filteredShots.length > 0 && !selectedShot) {
      setSelectedShot(filteredShots[0]);
    }
  }, [filteredShots, selectedShot]);

  // Function to convert percentage to pixel coordinates
  const convertPercentageToPixels = (percentX, percentY) => ({
    x: (percentX / 100) * svgDimensions.width,
    y: (percentY / 100) * svgDimensions.height
  });

  // Function to render the trajectory line
// Function to render the trajectory line
const renderTrajectoryLine = () => {
  if (!selectedShot || selectedShot.type !== "Field") return null; // Only render for "Field" type

  // Define goal line boundaries in percentage
  const goalLineStartX = 40; // Starting boundary of the goal line in percentage
  const goalLineEndX = 60;   // Ending boundary of the goal line in percentage

  // Define Y boundaries for the goal line
  const goalLineY = isMobile ? 89 : 94; // Fixed Y position for the goal line

  // Convert shot position from percentage to pixels
  const shotPosition = convertPercentageToPixels(selectedShot.x, selectedShot.y);

  // Convert goal line boundaries from percentage to pixels
  const goalLineStartPosition = convertPercentageToPixels(goalLineStartX, goalLineY);
  const goalLineEndPosition = convertPercentageToPixels(goalLineEndX, goalLineY);

  let goalPosition;

  if (selectedShot.result === "Goal") {
    // For goals, convert goal coordinates from percentage to pixels and ensure it falls within goal line boundaries
    const goalXInPixels = (selectedShot.goalX / 100) * svgDimensions.width;
    const goalYInPixels = (selectedShot.goalY / 100) * svgDimensions.height;

    goalPosition = {
      x: Math.max(goalLineStartPosition.x, Math.min(goalXInPixels, goalLineEndPosition.x)),
      y: Math.max(goalLineStartPosition.y, Math.min(goalYInPixels, goalLineEndPosition.y)),
    };
  } else {
    // For missed or blocked shots, use the provided goalX and goalY without restricting them to goal line boundaries
    goalPosition = {
      x: (selectedShot.goalX / 100) * svgDimensions.width,
      y: (selectedShot.goalY / 100) * svgDimensions.height,
    };
  }

  // For blocked shots, calculate the tick line (a small perpendicular line at the end of the trajectory)
  let tickLine = null;
  if (selectedShot.result === "Blocked") {
    const tickLength = 5; // Length of the tick line in pixels

    // Calculate the direction of the tick line perpendicular to the main trajectory
    const dx = goalPosition.x - shotPosition.x;
    const dy = goalPosition.y - shotPosition.y;
    const length = Math.sqrt(dx * dx + dy * dy);

    const tickStart = {
      x: goalPosition.x - (tickLength * dy) / length,
      y: goalPosition.y + (tickLength * dx) / length,
    };
    const tickEnd = {
      x: goalPosition.x + (tickLength * dy) / length,
      y: goalPosition.y - (tickLength * dx) / length,
    };

    tickLine = (
      <line
        x1={tickStart.x}
        y1={tickStart.y}
        x2={tickEnd.x}
        y2={tickEnd.y}
        stroke="#AF2418"
        strokeWidth="3"
      />
    );
  }

  return (
    <svg className="absolute top-0 left-0 w-full h-full pointer-events-none" style={{ zIndex: 10 }}>
      <line
        x1={shotPosition.x}
        y1={shotPosition.y}
        x2={goalPosition.x}
        y2={goalPosition.y}
        stroke="#AF2418" // Red for goals, grey for misses
        strokeWidth="1.5"
        strokeDasharray="1,1"
      />
      {tickLine}
    </svg>
  );
};

  
  

// Render shots only if dimensions are ready
const renderShots = () => {
  if (!shotsReady) return null;

  return filteredShots.map((shot, index) => {
    // Convert percentage coordinates to pixel coordinates
    const shotPosition = convertPercentageToPixels(shot.x, shot.y);

    const isSelected = selectedShot && selectedShot === shot;

    const shotStyle = {
      left: `${shotPosition.x - 8}px`, // Adjust to center the shot SVG
      top: `${shotPosition.y - 8}px`,  // Adjust to center the shot SVG
    };

    return (
      <div
        key={index}
        className="absolute w-5 h-5 cursor-pointer"
        style={shotStyle}
        title={shot.result}
        onClick={() => setSelectedShot(shot)}
      >
        <div
          className={`w-full h-full rounded-full ${isSelected ? "border-2 border-bgMain" : ""}`}
        >
          <img
            src={
              shot.result === "Goal"
                ? "/img/svg/goal.svg"
                : shot.result === "Blocked"
                ? "/img/svg/missed_shot.svg"  
                : "/img/svg/missed_shot.svg"
            }
            alt={shot.result}
            className="w-full h-full"
          />
        </div>
      </div>
    );
  });
};



  // Render shot details
  const renderShotDetailsMobile = () => {
    if (!selectedShot) return null;
  
    return (
      <div className="mt-4 p-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="pb-2">
              <p className="text-textSecondary text-lineupsText">Vrsta pogotka:</p>
              <p>{selectedShot.legUsed}</p>
            </div>
            <div className="pb-2">
              <p className="text-textSecondary text-lineupsText">Situacija:</p>
              <p>Brzi bijeg</p>
            </div>
            <div className="pb-1">
              <p className="text-textSecondary text-lineupsText">Rezultat:</p>
              <p>{selectedShot.result}</p>
            </div>
          </div>
  
          <div className="flex flex-col items-center">
            {selectedShot.type === "Goal" && (
              <div className="relative flex items-center justify-center p-4">
                <img src="/img/svg/goal_post.svg" alt="Goal Post" className="w-48 h-auto" />
                <img
                  src="/img/svg/goal.svg"
                  alt="Goal"
                  className="absolute w-4 h-4"
                  style={{ left: `${selectedShot.goalX}%`, top: `${selectedShot.goalY}%` }}
                />
              </div>
            )}
            {selectedShot.type === "Field" && (
              <div className="relative flex items-center justify-center p-4">
                <img src="/img/svg/goal_post.svg" alt="Field Shot" className="w-48 h-auto" />
                <img
                  src="/img/svg/goal.svg"
                  alt="Field"
                  className="absolute"
                  style={{ left: `${selectedShot.x}%`, top: `${selectedShot.y}%` }}
                />
              </div>
            )}
            <div className="flex justify-center w-full">
              <div className="grid grid-cols-2 gap-1 items-center">
                <div>
                  <p>{selectedShot.xG}</p>
                  <p className="text-textSecondary text-lineupsText">xG</p>
                </div>
                <div>
                  <p>{selectedShot.xGOT}</p>
                  <p className="text-textSecondary text-lineupsText">xGOT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const renderShotDetailsWeb = () => {
    if (!selectedShot) return null;
  
    return (
      <div className="hidden lg:flex mt-4 p-6">
        <div className="flex">
          {/* Text details column */}
          <div className="flex">
        {/* Text details column */}
        <div className="flex-shrink-0 mr-6">
          <div>
            <p className="text-textSecondary text-lineupsText font-light p-2">
              Vrsta pogotka: <span className="font-fw700">{selectedShot.legUsed}</span>
            </p>
            <p className="text-textSecondary text-lineupsText font-light p-2">
              Situacija: <span className="font-fw700">Brzi bijeg</span>
            </p>
            <p className="text-textSecondary text-lineupsText font-light	p-2">
              Rezultat: <span className="font-fw700">{selectedShot.result}</span>
            </p>
          </div>
        </div>

        </div>
          {/* SVG and xG container */}
          <div className="flex flex-1 items-start">
            {/* Flex container for SVG and xG details */}
            <div className="flex flex-1 justify-between items-start">
              {/* SVG container */}
              <div className="flex flex-1 items-center justify-center">
                {selectedShot.type === "Goal" && (
                  <div className="relative flex items-center justify-center">
                    <img src="/img/svg/goal_post.svg" alt="Goal Post" className="w-64 h-auto" />
                    <img
                      src="/img/svg/goal.svg"
                      alt="Goal"
                      className="absolute w-6 h-6"
                      style={{ left: `${selectedShot.goalX}%`, top: `${selectedShot.goalY}%` }}
                    />
                  </div>
                )}
                {selectedShot.type === "Field" && (
                  <div className="relative flex items-center justify-center">
                    <img src="/img/svg/goal_post.svg" alt="Field Shot" className="w-64 h-auto" />
                    <img
                      src="/img/svg/goal.svg"
                      alt="Field"
                      className="absolute"
                      style={{ left: `${selectedShot.x}%`, top: `${selectedShot.y}%` }}
                    />
                  </div>
                )}
              </div>
  
              {/* xG container with border */}
              <div className="flex items-center pl-6 mx-4">
                <div className="flex flex-col items-center border border-gray-300 rounded-lg p-2 bg-white inline-flex">
                  {/* xG Value */}
                  <div className="flex flex-col items-center mb-1">
                    <p className="text-lg font-medium">{selectedShot.xG}</p>
                    <p className="text-textSecondary text-lineupsText">xG</p>
                  </div>
                  {/* xGOT Value */}
                  <div className="flex flex-col items-center">
                    <p className="text-lg font-medium">{selectedShot.xGOT}</p>
                    <p className="text-textSecondary text-lineupsText">xGOT</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const renderButtons = () => (
    <div className="flex justify-center mb-4 ">
      <button
        onClick={() => setSelectedText("Teren")}
        className={`flex items-center justify-center rounded ${
          selectedText === "Teren" ? "bg-bgMain text-white" : "bg-containerPast text-white"
        } ${isMobile ? 'w-auto' : 'flex-grow'} h-10 px-4 py-2 transition-all duration-300`}
        style={{ flexBasis: isMobile ? 'auto' : '100px' }}
      >
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill={selectedText === "Teren" ? "white" : "#93939F"}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0002 2C20.0002 0.895431 19.1048 0 18.0002 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895428 16 2 16H18.0002C19.1048 16 20.0002 15.1046 20.0002 14V2ZM1.81787 1.7767H9.09082V5.4839C8.03172 5.85007 7.27295 6.83775 7.27295 7.99871C7.27295 9.15967 8.03172 10.1474 9.09082 10.5135V14.2211H1.81787V11.5567H3.45427C4.55884 11.5567 5.45427 10.6613 5.45427 9.55674V6.44562C5.45427 5.34106 4.55885 4.44562 3.45428 4.44562H1.81787V1.7767ZM10.909 10.5138V14.2211H18.1817V11.5567H16.5459C15.4413 11.5567 14.5459 10.6613 14.5459 9.55674V6.44562C14.5459 5.34105 15.4413 4.44562 16.5459 4.44562H18.1817V1.7767H10.909V5.48367C11.9685 5.84966 12.7276 6.83751 12.7276 7.99871C12.7276 9.15992 11.9685 10.1478 10.909 10.5138ZM18.1817 9.77896V6.2234H16.3641V9.77896H18.1817ZM3.63607 9.77896H1.81787V6.2234H3.63607V9.77896Z"
          />
        </svg>
        {!isMobile && <span className="ml-2 text-lg">Teren</span>}
      </button>
  
      <button
        onClick={() => setSelectedText("Gol")}
        className={`flex items-center justify-center rounded ${
          selectedText === "Gol" ? "bg-bgMain text-white" : "bg-containerPast text-gray-500"
        } ${isMobile ? 'w-auto' : 'flex-grow'} h-10 px-4 py-2 transition-all duration-300`}
        style={{ flexBasis: isMobile ? 'auto' : '100px' }}
      >
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill={selectedText === "Gol" ? "white" : "#93939F"}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 2C20 0.895431 19.1046 0 18 0H2C0.895431 0 0 0.895431 0 2V15.1667C0 15.6269 0.373096 16 0.833333 16C1.29357 16 1.66667 15.6269 1.66667 15.1667V3.03531L3.33388 4.81405V13.3896C3.33388 13.8498 3.70698 14.2229 4.16722 14.2229C4.62745 14.2229 5.00055 13.8498 5.00055 13.3896V5.33485H15V13.3896C15 13.8498 15.3731 14.2229 15.8333 14.2229C16.2936 14.2229 16.6667 13.8498 16.6667 13.3896V4.81316L18.3333 3.03549V15.1667C18.3333 15.6269 18.7064 16 19.1667 16C19.6269 16 20 15.6269 20 15.1667V2ZM17.1555 1.77778H2.84506L4.51315 3.55707H15.4874L17.1555 1.77778Z"
          />
        </svg>
        {!isMobile && <span className="ml-2 text-lg">Gol</span>}
      </button>
    </div>
  );
  
  
  
  return (
    <div className="w-full my-4">
      <table className="table-auto w-full">
        <tbody>
          <tr>
            <td className="flex items-center justify-between">
            <div className="flex flex-col">
  <div className="flex items-center space-x-2">
    <span className="font-medium">
      {isMobile ? "Mapa udaraca:" : "Sezonski udarci prema golu"}
    </span>
    <span className="text-gray-600">
      {isMobile ? selectedText : null}
    </span>
  </div>
  <div className="flex items-center justify-between">
    <span className="text-gray-500 text-sm">
      67% točnost podataka
    </span>
  </div>
</div>
              <div className="flex items-center space-x-4 ml-4">
                {renderButtons()}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Web and Mobile SVG Views */}
      <div className="flex flex-col items-center">
        <div
          className="relative flex items-center justify-center p-4"
          key={selectedText}
          ref={svgRef}
          style={{
            width: isMobile ? '100%' : '90%',
            height: 'auto',
            maxWidth: isMobile ? '400px' : '700px',
            margin: '0 auto',
            position: 'relative',
          }}
        >
          {selectedText === "Teren" && (
            <svg className="absolute w-full h-full">
              {renderTrajectoryLine()}
            </svg>
          )}
          <img
            src={
              selectedText === "Teren"
                ? "/img/svg/field_shot.svg"
                : "/img/svg/goal_post.svg"
            }
            alt={selectedText}
            className="h-auto w-full"
          />
          {renderShots()}
        </div>

        {isMobile ? renderShotDetailsMobile() : renderShotDetailsWeb()}
      </div>
    </div>
  );
};

export default ShotMap;
