import React, { useEffect, useState } from "react";
import Loader from "../shared/Loader";
import HeadingTitle from "../components/layout/HeadingTitle";
import { fetchStandingsData } from "../services/league-service";
import { useLocation } from "react-router-dom";
import { Chip } from "./Chip";
import {
  TeamStandingsAway,
  TeamStandingsForm,
  TeamStandingsHome,
  TeamStandingsOverall,
} from "./TeamStandings";

export function TeamStandingsTable({
  leagueShortname,
  leagueName,
  homeTeam,
  guestTeam,
  matchId,
}) {
  const [standingsData, setStandingsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("overall");
  const location = useLocation();
  const isCompetitionScreen =
    !location.pathname.includes("team") &&
    !location.pathname.includes("match") &&
    !location.pathname.includes("prematch");

  useEffect(() => {
    const fetchStandings = async () => {
      if (leagueShortname) {
        const sortedStandings = await fetchStandingsData(leagueShortname);
        setStandingsData(sortedStandings);
        setIsLoading(false);
      }
    };

    fetchStandings();
  }, [leagueShortname]);

  const filterSubLeagues = (subLeagues) => {
    return subLeagues.filter((subLeague) => {
      const teamIds = subLeague.teams.map((team) => team.teamId);
      return teamIds.includes(homeTeam.id) || teamIds.includes(guestTeam.id);
    });
  };

  const displayedStandings = isCompetitionScreen
    ? standingsData
    : filterSubLeagues(standingsData);

  const chips = [
    {
      label: "Sve",
      to: "overall",
    },
    {
      label: "Doma",
      to: "home",
    },
    {
      label: "Gosti",
      to: "away",
    },
    {
      label: "Forma",
      to: "form",
    },
  ];

  return (
    <div>
      {leagueShortname && (
        <div className="container mx-auto">
          {/* <HeadingTitle title={leagueName} /> */}
          <div className="flex justify-center my-2 gap-x-2">
            {chips.map(({ label, to }, index) => (
              <Chip
                onClick={() => setView(to)}
                active={to === view}
                key={index}
              >
                {label}
              </Chip>
            ))}
          </div>
          <div className="bg-white rounded overflow-x-auto">
            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <Loader />
              </div>
            ) : (
              displayedStandings.map((subLeague, index) => (
                <div key={index}>
                  <div className="mb-2">
                    {subLeague.hasSubLeague && (
                      <HeadingTitle
                        title={`Tablica - ${subLeague.subLeagueName}`}
                      />
                    )}
                  </div>
                  {view === "overall" && (
                    <TeamStandingsOverall
                      standingsData={subLeague.teams}
                      homeTeam={homeTeam}
                      guestTeam={guestTeam}
                      matchId={matchId}
                    />
                  )}
                  {view === "home" && (
                    <TeamStandingsHome
                      standingsData={subLeague.teams}
                      homeTeam={homeTeam}
                      guestTeam={guestTeam}
                      matchId={matchId}
                    />
                  )}
                  {view === "away" && (
                    <TeamStandingsAway
                      standingsData={subLeague.teams}
                      homeTeam={homeTeam}
                      guestTeam={guestTeam}
                      matchId={matchId}
                    />
                  )}
                  {view === "form" && (
                    <TeamStandingsForm
                      standingsData={subLeague.teams}
                      homeTeam={homeTeam}
                      guestTeam={guestTeam}
                      matchId={matchId}
                    />
                  )}
                </div>
              ))
            )}
          </div>
          <div className="mt-4 mb-4 flex justify-start">
            <div className="flex flex-col items-start space-y-2 text-sm pl-4">
              <div className="flex items-center">
                <div className="w-3 h-3 bg-green-500 rounded-sm mr-2"></div>
                <span className="text-scoreText">Liga prvaka - kvalifikacije</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-blue-500 rounded-sm mr-2"></div>
                <span className="text-scoreText">Konferencijska liga - kvalifikacije</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-bgMain rounded-sm mr-2"></div>
                <span className="text-scoreText">Relegacija</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
