import React from 'react';
import { useNavigation, getMatchResultLabel } from '../services/shared-service';
import { useLocation, useParams } from 'react-router-dom';

const sortTeamData = (standingsData, isHome, isAway) => {
  return [...standingsData].sort((a, b) => {
    const getGoalDifference = (team, isHome, isAway) => {
      if (isHome) {
        return team.homeScored - team.awayReceived;
      } else if (isAway) {
        return team.awayScored - team.homeReceived;
      } else {
        return (
          team.homeScored +
          team.awayScored -
          (team.homeReceived + team.awayReceived)
        );
      }
    };

    if (isHome) {
      if (b.homePoints !== a.homePoints) {
        return b.homePoints - a.homePoints;
      }
      const goalDiffA = getGoalDifference(a, true, false);
      const goalDiffB = getGoalDifference(b, true, false);
      if (goalDiffB !== goalDiffA) {
        return goalDiffB - goalDiffA;
      }
      return b.homeScored - a.homeScored;
    } else if (isAway) {
      if (b.awayPoints !== a.awayPoints) {
        return b.awayPoints - a.awayPoints;
      }
      const goalDiffA = getGoalDifference(a, false, true);
      const goalDiffB = getGoalDifference(b, false, true);
      if (goalDiffA !== goalDiffB) {
        return goalDiffB - goalDiffA;
      }
      return b.awayScored - a.awayScored;
    } else {
      if (b.pointsOverall !== a.pointsOverall) {
        return b.pointsOverall - a.pointsOverall;
      }
      const goalDiffA = getGoalDifference(a, false, false);
      const goalDiffB = getGoalDifference(b, false, false);
      if (goalDiffA !== goalDiffB) {
        return goalDiffB - goalDiffA;
      }
      return b.homeScored + b.awayScored - (a.homeScored + a.awayScored);
    }
  });
};

const TeamTableHeader = ({ headers }) => (
  <tr className="border-b border-divider">
    {headers.map((header, index) => (
      <th
        className={`font-normal text-textSecondary pb-2 
                  ${index !== 1 ? 'text-center' : 'text-left'} 
                  ${index === headers.length - 1 && 'pr-3'} 
                  ${index === 0 && 'pr-1'}`}
        key={index}
      >
        {header}
      </th>
    ))}
  </tr>
);

const TeamTableRow = ({
  team,
  teamId,
  homeTeam,
  guestTeam,
  isTeamScreen,
  isMatchScreen,
  onTeamClick,
  headers,
  idx,
  isHome,
  isAway,
 totalTeams
}) => {

  const positionClasses =
    idx === 0 
      ? 'bg-green-500 text-white rounded px-2 py-1'
      : idx === 1 || idx === 2
      ? 'bg-blue-500 text-white rounded px-2 py-1'
      : idx === totalTeams - 1
    ? 'bg-bgMain text-white rounded px-2 py-1'
    : '';

  return (
    <tr
      key={team['teamId']}
      className={`border-b border-divider ${
        (isTeamScreen && team.teamId === Number(teamId)) ||
        (isMatchScreen &&
          (team.teamId === homeTeam.id || team.teamId === guestTeam.id))
          ? 'bg-gray-100'
          : ''
      }`}
    >
      {/* INDEX */}
      <td className="py-3 pr-1 text-center font-medium text-scoreText text-black">
        <span className={positionClasses}>{idx + 1}</span>
      </td>

      {/* TEAM */}
      {headers.includes('Momčad') && (
        <td className="py-3 flex font-medium">
          <div className="cursor-pointer" onClick={onTeamClick}>
            <div className="flex items-center">
              <div className="rounded-full h-[24px] w-[24px] grid place-content-center overflow-hidden border-2 border-gray-300">
                <img
                  src={team.teamLogo}
                  alt={team['teamName']}
                  className="object-cover h-[24px] w-[24px]"
                />
              </div>
              <span
                className={`ml-3 ${
                  team.teamId === homeTeam.id || team.teamId === guestTeam.id
                    ? 'font-bold'
                    : 'font-normal'
                } text-scoreText text-black`}
              >
                {team['teamName']}
              </span>
            </div>
          </div>
        </td>
      )}

      {/* OVERALL */}
      {headers.includes('OS') && (
        <td
          className="py-3 text-center text-scoreText text-black"
          style={{ width: '16px' }}
        >
          {isHome
            ? team['homeMatches']
            : isAway
            ? team['awayMatches']
            : team['matchesOverall']}
        </td>
      )}

      {/* SCORED/RECEIVED */}
      {headers.includes('GR') && (
        <td
          className="py-3 px-3 text-center text-scoreText text-black"
          style={{ width: '26px' }}
        >
          {isHome
            ? `${team['homeScored']}:${team['awayReceived']}`
            : isAway
            ? `${team['awayScored']}:${team['homeReceived']}`
            : `${team['homeScored'] + team['awayScored']}:${
                team['homeReceived'] + team['awayReceived']
              }`}
        </td>
      )}

      {/* POINTS */}
      {headers.includes('B') && (
        <td
          className="py-3 pr-3 text-center font-bold text-scoreText text-black"
          style={{ width: '16px' }}
        >
          {isHome
            ? team['homePoints']
            : isAway
            ? team['awayPoints']
            : team['pointsOverall']}
        </td>
      )}
    </tr>
  );
};

export function TeamStandingsOverall({
  standingsData,
  homeTeam,
  guestTeam,
  matchId,
}) {
  const navigateTo = useNavigation();
  const location = useLocation();
  const isTeamScreen = location.pathname.includes('team');
  const isMatchScreen = location.pathname.includes('match');
  const totalTeams = standingsData.length;
  const headers = ['#', 'Momčad', 'OS', 'GR', 'B'];
  const { teamId } = useParams();

  return (
    <div>
      {standingsData && (
        <table className="min-w-max w-full table-auto">
          <tbody className="text-scoreText">
            <TeamTableHeader headers={headers} />
            {sortTeamData(standingsData, false, false).map((team, idx) => (
              <TeamTableRow
                key={idx}
                headers={headers}
                guestTeam={guestTeam}
                homeTeam={homeTeam}
                teamId={teamId}
                idx={idx}
                isMatchScreen={isMatchScreen}
                isTeamScreen={isTeamScreen}
                isAway={false}
                isHome={false}
                totalTeams={totalTeams}
                onTeamClick={() =>
                  navigateTo(`/team/${team['teamId']}?matchId=${matchId}`)
                }
                team={team}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export function TeamStandingsHome({
  standingsData,
  homeTeam,
  guestTeam,
  matchId,
}) {
  const navigateTo = useNavigation();
  const location = useLocation();
  const isTeamScreen = location.pathname.includes('team');
  const isMatchScreen = location.pathname.includes('match');
  const headers = ['#', 'Momčad', 'OS', 'GR', 'B'];
  const totalTeams = standingsData.length;
  return (
    <div>
      {standingsData && (
        <table className="min-w-max w-full table-auto">
          <tbody className="text-scoreText">
            <TeamTableHeader headers={headers} />
            {sortTeamData(standingsData, true, false).map((team, idx) => (
              <TeamTableRow
                key={idx}
                team={team}
                headers={headers}
                idx={idx}
                onTeamClick={() =>
                  navigateTo(`/team/${team['teamId']}?matchId=${matchId}`)
                }
                isTeamScreen={isTeamScreen}
                isMatchScreen={isMatchScreen}
                homeTeam={homeTeam}
                guestTeam={guestTeam}
                isHome={true}
                isAway={false}
                totalTeams={totalTeams}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export function TeamStandingsAway({
  standingsData,
  homeTeam,
  guestTeam,
  matchId,
}) {
  const navigateTo = useNavigation();
  const location = useLocation();
  const isTeamScreen = location.pathname.includes('team');
  const isMatchScreen = location.pathname.includes('match');
  const headers = ['#', 'Momčad', 'OS', 'GR', 'B'];
  const totalTeams = standingsData.length;
  return (
    <div>
      {standingsData && (
        <table className="min-w-max w-full table-auto">
          <tbody className="text-scoreText">
            <TeamTableHeader headers={headers} />
            {sortTeamData(standingsData, false, true).map((team, idx) => (
              <TeamTableRow
                key={idx}
                idx={idx}
                headers={headers}
                guestTeam={guestTeam}
                homeTeam={homeTeam}
                isMatchScreen={isMatchScreen}
                isTeamScreen={isTeamScreen}
                isHome={false}
                isAway={true}
                onTeamClick={() =>
                  navigateTo(`/team/${team['teamId']}?matchId=${matchId}`)
                }
                team={team}
                totalTeams={totalTeams}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export function TeamStandingsForm({
  standingsData,
  homeTeam,
  guestTeam,
  matchId,
}) {
  const navigateTo = useNavigation();
  const location = useLocation();
  const isTeamScreen = location.pathname.includes('team');
  const isMatchScreen = location.pathname.includes('match');
  const { teamId } = useParams();
  const totalTeams = standingsData.length;
  return (
    <div>
      {standingsData && (
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-scoreText border-b border-divider">
              <th className="pb-2 font-normal text-center text-textSecondary">
                #
              </th>
              <th className="pb-2 pl-2 font-normal text-left text-textSecondary">
                Momčad
              </th>
              <th className="pb-2 pr-12 font-bold text-right text-textSecondary">
                Forma
              </th>
            </tr>
          </thead>
          <tbody className="text-scoreText">
            {standingsData.map((team, index) => {
              const positionClasses =
                index === 0 
                  ? 'bg-green-500 text-white rounded px-2 py-1'
                  : index === 1 || index === 2
                  ? 'bg-blue-500 text-white rounded px-2 py-1'
                  : index === totalTeams - 1
                  ? 'bg-bgMain text-white rounded px-2 py-1'
                  : '';
              
              return (
                <tr
                  key={team['teamId']}
                  className={`border-b border-divider ${
                    (isTeamScreen && team.teamId === Number(teamId)) ||
                    (isMatchScreen &&
                      (team.teamId === homeTeam.id ||
                        team.teamId === guestTeam.id))
                      ? 'bg-gray-100'
                      : ''
                  }`}
                >
                  <td className="py-3 px-1 text-center font-medium text-scoreText text-black">
                    <span className={positionClasses}>{index + 1}</span>
                  </td>
                  <td className="py-3 ml-2 flex font-medium">
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        navigateTo(
                          `/team/${team.teamId}${
                            matchId ? `?matchId=${matchId}` : ''
                          }`
                        )
                      }
                    >
                      <div className="flex items-center">
                        <div className="rounded-full h-[24px] w-[24px] grid place-content-center overflow-hidden border-2 border-gray-300">
                          <img
                            src={team.teamLogo}
                            alt={team['teamName']}
                            className="object-cover h-[24px] w-[24px]"
                          />
                        </div>
                        <span
                          className={`ml-3 ${
                            team.teamId === homeTeam.id ||
                            team.teamId === guestTeam.id
                              ? 'font-bold'
                              : 'font-normal'
                          } text-scoreText text-black`}
                        >
                          {team['teamName']}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-end">
                      {team.form.slice(-5).map((result, index) => (
                        <div
                          key={index}
                          className={`rounded w-5 h-5 flex items-center justify-center mx-1 ${
                            result === 'W'
                              ? 'bg-green-500 text-white'
                              : result === 'L'
                              ? 'bg-red-500 text-white'
                              : 'bg-blue-500 text-white'
                          }`}
                        >
                          <span className="font-bold">
                            {getMatchResultLabel(result)}
                          </span>
                        </div>
                      ))}
                      {[...Array(5 - team.form.slice(-5).length)].map(
                        (_, emptyIndex) => (
                          <div
                            key={emptyIndex}
                            className="rounded w-5 h-5 flex items-center justify-center mx-1 bg-gray-200 text-white"
                          >
                            <span className="font-bold"></span>
                          </div>
                        )
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

